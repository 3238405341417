const QrCodeHelper = {
  _video: document.createElement("video"),
  _canvasElement: document.getElementById("qr-reader-canvas"),
  _canvas: document.getElementById("qr-reader-canvas") && document.getElementById("qr-reader-canvas").getContext("2d"),
  _detected: false
};

QrCodeHelper.init = function () {
  // Use facingMode: environment to attemt to get the front camera on phones
  if(navigator.mediaDevices)
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then(function(stream) {
        QrCodeHelper._video.srcObject = stream;
        QrCodeHelper._video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
        QrCodeHelper._video.play();
        requestAnimationFrame(QrCodeHelper.refreshVideoImage);
      });
};


QrCodeHelper.refreshVideoImage = function () {
  if (QrCodeHelper._video.readyState === QrCodeHelper._video.HAVE_ENOUGH_DATA) {
    QrCodeHelper._canvasElement.hidden = false;
    QrCodeHelper._canvasElement.height = QrCodeHelper._video.videoHeight;
    QrCodeHelper._canvasElement.width = QrCodeHelper._video.videoWidth;
    QrCodeHelper._canvas.drawImage(QrCodeHelper._video, 0, 0, QrCodeHelper._canvasElement.width, QrCodeHelper._canvasElement.height);

    const imageData = QrCodeHelper._canvas.getImageData(
      0,
      0,
      QrCodeHelper._canvasElement.width,
      QrCodeHelper._canvasElement.height
    );

    QrCodeHelper.findQrCode(imageData);
  }
  requestAnimationFrame(QrCodeHelper.refreshVideoImage);
};


QrCodeHelper.findQrCode = function (imageData) {
  if(QrCodeHelper._detected)
    return;

  const code = jsQR(imageData.data, imageData.width, imageData.height);

  if (code) {
    QrCodeHelper.drawBoxQr(
      code.location.topLeftCorner,
      code.location.topRightCorner,
      code.location.bottomRightCorner,
      code.location.bottomLeftCorner,
      "#00ff00"
    );

    if(code.data) {
      QrCodeHelper._detected = true;

      const newLink = code.data.replace('utm_medium=print', 'utm_medium=in-app');

      document.querySelector('.qr-reader__loading').classList.add('qr-reader__loading--active');

      window.location = newLink;
    }
  }
}


QrCodeHelper.drawBoxQr = function (begin, b, c, d, color) {
  QrCodeHelper._canvas.beginPath();
  QrCodeHelper._canvas.moveTo(begin.x, begin.y);
  QrCodeHelper._canvas.lineTo(b.x, b.y);
  QrCodeHelper._canvas.lineTo(c.x, c.y);
  QrCodeHelper._canvas.lineTo(d.x, d.y);
  QrCodeHelper._canvas.lineTo(begin.x, begin.y);
  QrCodeHelper._canvas.lineWidth = 4;
  QrCodeHelper._canvas.strokeStyle = color;
  QrCodeHelper._canvas.stroke();
}
